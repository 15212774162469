<template>
  <Content :search="false">
    <template v-slot:topbar>
      <div class="flex w-full items-center justify-end">
        <AddCompanyForm />
      </div>
    </template>

    <template v-slot:content>
      <template v-if="companyForms">
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <template v-for="item in companyForms.data" :key="item.id">
              <SingleCompanyForm :item="item" />
            </template>
          </ul>
        </div>

        <Pagination :meta="companyForms.meta" :onPageChange="onPageChange" />
      </template>

      <template v-else>
          <p class="py-6 text-center dark:text-gray-300">
            Diemžēl nekas netika atrasts
          </p>
      </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import AddCompanyForm from "@/components/Settings/CompanySettings/CompanyForms/AddCompanyForm";
import SingleCompanyForm from "@/components/Settings/CompanySettings/CompanyForms/SingleCompanyForm";
import Pagination from "@/components/Components/Pagination";

export default {
  name: "CompanyForms",
  components: {
    AddCompanyForm,
    SingleCompanyForm,
    Pagination,
  },
  data: () => ({
    search: ''
  }),
  created() {
    this.$store.dispatch("setDataLoading", true)
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('getCompanyForms', 1)
  },
  computed: {
    ...mapGetters({
      companyForms: 'companyForms',
      formsForDisplay: 'formsForDisplay',
      editableItem: 'editableItem'
    })
  },
  methods: {
    onPageChange(page) {
      this.$store.dispatch('getCompanyForms', page);
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    hideCompanyFormForm(){
      this.$store.dispatch('removeAllFormsForDisplay')
    },
  }
}
</script>