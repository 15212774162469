<template>
  <li>
    <item-card :content="false">
      <template v-slot:title>
        <template v-if="editableItem.id !== item.id">
          <item-text title="Nosaukums" :text="item.name" />
        </template>
      </template>
      <template v-slot:buttons>

        <template v-if="editableItem.id !== item.id">

          <Button icon="pencil" @click="editItem(item)" />

          <Button icon="delete" @click="deleteCompanyForm(item.id)" />

        </template>
      </template>

      <template v-slot:content>
      </template>
      <template v-slot:additional>

        <template v-if="editableItem.id === item.id">
          <EditCompanyForm :item="item"/>
        </template>

      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex"
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import EditCompanyForm from "@/components/Settings/CompanySettings/CompanyForms/EditCompanyForm"

export default {
  name: "SingleCompanyForm",
  components: {
    ItemCard,
    ItemText,
    EditCompanyForm,
  },
  props: ['item'],
  computed: {
    ...mapGetters({
      editableItem: 'editableItem'
    })
  },
  methods: {
    editItem(item){
      this.$store.dispatch('setEditableItem', item)
    },
    deleteCompanyForm(itemId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteCompanyForm', itemId)
      }
    }
  }
}
</script>
